<template>
  <div class="signSuccess-wrap">
    <div class="img-wrap">
      <img src="@/assets/imgs/authenticationError.png" alt />
    </div>
    <div class="des">
      <p>很抱歉当前版本不支持移动端进行企业认证，请您在pc端登录账号</p>
      <p>方式一：复制网址到电脑端操作</p>
      <p>
        {{ systemInfo.hostPC }}&nbsp;&nbsp;
        <van-button type="info" @click="copy" size="mini">复制</van-button>
      </p>
      <p>
        方式二：电脑端浏览器搜索【{{ systemInfo.company }}】，登录后即可查看处理
      </p>
    </div>
    <!-- <section class="des">
      <p style="text-align: center;">请在【电脑端】刷新页面，继续操作</p>
    </section> -->
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { Toast } from 'vant'

  export default {
    data() {
      return {
        url: 'https://saas.ecloudsign.com',
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
    },
    methods: {
      copy() {
        const oInput = document.createElement('input')
        oInput.value = this.systemInfo.hostPC
        document.body.appendChild(oInput)
        oInput.select() // 选择对象
        document.execCommand('Copy') // 执行浏览器复制命令
        oInput.className = 'oInput'
        oInput.style.display = 'none'
        Toast('复制成功!')
      },
    },
  }
</script>
<style lang="less" scoped>
  .signSuccess-wrap {
    .img-wrap {
      margin: 90px auto 16px;
      width: 120px;
      height: 120px;
      img {
        width: 120px;
        height: 120px;
      }
    }
    .title {
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(17, 26, 52, 1);
      line-height: 24px;
      text-align: center;
    }
    .des {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(103, 114, 131, 1);
      text-align: center;
      line-height: 30px;
      margin-bottom: 20px;
      padding: 0 20px;
      p {
        text-align: left;
      }
    }
    .btn {
      width: 327px;
      height: 50px;
      background: rgba(22, 118, 255, 1);
      box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
      border-radius: 25px;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 50px;
    }
  }
</style>
